// Q1の下に引く線
.titleBorder {
    position: relative;
    text-align: center;
    font-size: 1.5rem; /* テキストサイズを調整 */
    margin: 1rem 0; /* テキストの上下のマージンを調整 */
}

.titleBorder::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 44%;
    width: 40px;
    height: 2px; /* 線の太さを調整 */
    background-color: #000; /* 線の色を指定 */
    transform-origin: left top;
}

// 残りの質問数テキスト
.remaining-text{
    position: relative;
    background-color: #FFD900;
    border-radius: 8px;
    width: 45%;
    margin: 0 auto 5% auto;
    padding: 2%;
    text-align: center;
    font-size: 11px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 40%;
        border: solid transparent;
        border-width: 14px;
        border-top-color: #FFD900;
    }
}
// 残りの質問数バー
.progress-bar {
    background-color: #ccc; /* 全体のバーの背景色（グレー） */
    height: 10px; /* バーの高さを設定 */
    border-radius: 5px;

    .progress-bar-fill {
        background-color: #FFD900; /* 現在の質問数の割合バーの背景色 */
        height: 100%; /* バーの高さを100%に設定 */
        border-radius: 5px;
    }
}