// buttonリンクを囲むエリア
.next-step-button-container{
    top: 80%;
    left: 57%;
}

// 診断開始ボタン
.next-step-button {
	display: block;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	margin: auto;
	padding: 1rem 2rem;
	border-radius: 100vw;
	color: #fff;
	background: #14da3f;
	background-position: right center;
	background-size: 200% auto;
	-webkit-animation: pulse 2s infinite;
	animation: shad-next-step 1.5s infinite;
}

// buttonのアニメーション
@keyframes pulse {
	to {
		background-position: left center;
	}
}

@keyframes shad-next-step {
	0% {box-shadow: 0 0 0 0 #14da3f;}
	70% {box-shadow: 0 0 0 10px rgb(39 172 217 / 0%);}
	100% {box-shadow: 0 0 0 0 rgb(39 172 217 / 0%);}
}