
// 画像がはみ出した場合画像と同じ背景色にする
.bg-start{
    background-color: #fef9f5;
}

// 画像を囲むエリア
.start-img-area{
    position: relative;
}

// buttonリンクを囲むエリア
.start-button-container{
    top: 70%;
    left: 33%;
}

// 診断開始ボタン
.start_button {
	display: block;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	margin: auto;
	padding: 1rem 4rem;
	border-radius: 100vw;
	color: #fff;
	background: #2d97b8;
	background-position: right center;
	background-size: 200% auto;
	-webkit-animation: pulse 2s infinite;
	animation: shad-start 1.5s infinite;
}

// buttonのアニメーション
@keyframes pulse {
	to {
		background-position: left center;
	}
}

@keyframes shad-start {
	0% {box-shadow: 0 0 0 0 #2d97b8;}
	70% {box-shadow: 0 0 0 10px rgb(39 172 217 / 0%);}
	100% {box-shadow: 0 0 0 0 rgb(39 172 217 / 0%);}
}