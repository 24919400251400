@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// 他のSCSSファイルをインポート
@import './Start.scss';
@import './Question.scss';
@import './Result.scss';
@import './NextStep.scss';



/*
★★★
tailwindに無いクラスで使いまわしそうなもの
★★★
*/
// width
.w-90{
    width: 90%;
}

// height
.h-90{
    height: 90%;
}

.min-h-90{
    height: 90vh;
}

/*
★★★
カラーの指定
★★★
*/

// メインの背景色
.bg-mian{
    background-color: #E5F9FF;
}

// メイン(内側)の背景色
.bg-inner{
    background-color: #FFFFFF;
}

.bg-main-color{
    background-color: #FFD900;
}

.bg-start{
    background-color: #fef9f5;
}

/*
★★★
その他共通要素
★★★
*/

// チェックボックス
.checkBox:hover {
    background: #51d4fc;
    opacity: 1;
    transition: 0.5s;
}